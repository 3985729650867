<template>
	<div class="documentoaduaneroFormHeader puiformheader">
		<v-layout row wrap>
			<v-flex xs12 md2>
				<pui-field :label="$t('header.documentoaduanero.numdocumento')" :value="getNumdocumentoValue"></pui-field>
			</v-flex>
			<v-flex xs12 md2>
				<pui-field :label="$t('header.documentoaduanero.tipodoc')" :value="getTipodocValue"></pui-field>
			</v-flex>
			<v-flex xs12 md2>
				<pui-field :label="$t('header.documentoaduanero.fecdocumento')" :value="getFecdocumentoValue"></pui-field>
			</v-flex>
			<v-flex xs12 md2>
				<pui-field :label="$t('header.documentoaduanero.estado')" :value="getEstadoValue"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'documentoaduanero-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'documentoaduanero'
		};
	},
	computed: {
		getTipodocValue() {
			return this.model && this.model.tipodoc ? this.model.tipodoc : '-';
		},
		getNumdocumentoValue() {
			return this.model && this.model.numdocumento ? this.model.numdocumento : '-';
		},
		getFecdocumentoValue() {
			return this.model && this.model.fecdocumento ? this.$moment(this.model.fecdocumento).format('DD/MM/YYYY HH:mm') : '-';
		},
		getEstadoValue() {
			return this.model && this.model.estado ? this.model.estado : '-';
		}
	}
};
</script>
